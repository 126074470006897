import { Injectable } from '@angular/core';
import { environment } from '../assets/config/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {
  private token: string = '';
  private apiUrl: string;
  private apiLoginUrl: string;
  private isAdminFlag: boolean = false;
  private OrgData: any = {};

  constructor() {
    //this.apiUrl = 'https://knowledge-qae.finthrivenp.com/KS-Registration-API/api/';
    this.apiUrl = environment.apiUrl;
    this.apiLoginUrl = environment.apiLoginUrl;
    console.log(this.apiUrl);
    console.log(environment);
  }

  // ngOnInit(): void {
  //   this.apiUrl = environment.apiUrl;
  //   this.apiLoginUrl = environment.apiLoginUrl;
  // }

  setToken(token: string): void {
    this.token = token;
  }

  getToken(): string {
    return this.token;
  }

  getApiUrl(): string {
    return this.apiUrl;
  }
  getApiLoginUrl(): string {
    return this.apiLoginUrl;
  }
  setIsAdminFlag(flag: boolean): void {
    this.isAdminFlag = flag;
  }

  getIsAdminFlag(): boolean {
    return this.isAdminFlag;
  }

  setOrgData(Values: any): void {
    this.OrgData = Values;
  }

  getOrgData(): any {
    return this.OrgData;
  }

}